.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .error-page h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .error-page p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .refresh-button {
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
    background-color: #f44336;
    color: #fff;
    border: none;
    /* border-radius: 4px; */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .refresh-button:hover {
    background-color: #d32f2f;
  }
  