html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-family:  -apple-system, BlinkMacSystemFont,'Gantari-Regular','Gantari', 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* i didn't find another way for override  */

div.MuiPopover-root {
    z-index: 9999999999 !important;
}

div.MuiDialog-root {
    z-index: 9999999999 !important;
}

div.MuiDialog-root {
    z-index: 9999999999 !important;
}
.filterSelect .MuiOutlinedInput-input{
font-size: 14px !important;
}
.MuiButton-contained{
    border-radius: 0px !important;
}
.MuiAccordion-root{
    background-color: '#FDFBF9 !important';
}
.MuiAccordionSummary-content{
    background-color: '#FDFBF9 !important';
}
.filterSelect .MuiSelect-iconOutlined{
    right: 0;
}
@media screen and (min-width: 1241px) {
    .intercom-launcher-frame, .intercom-lightweight-app-launcher {
        right: 30px !important;
    }

    .intercom-launcher-badge-frame.intercom-fknrws.e18uebig0 {
        right: 20px !important;
      
    }
}

@media screen and (max-width: 1240px) {
    .intercom-launcher-frame, .intercom-lightweight-app-launcher {
        bottom: 70px !important;
        width: 60px !important;
        height: 60px !important;
        right: 32px !important;
    }

    .intercom-launcher-frame .intercom-launcher, .intercom-lightweight-app-launcher .intercom-launcher {
        width: 55px !important;
        height: 55px !important;
    }

    .intercom-lightweight-app-launcher-custom-icon-open {
        max-height: 30px !important;
        max-width: 30px !important;
    }
}

@media screen and (max-width: 768px) {
    .intercom-launcher-frame, .intercom-lightweight-app-launcher {
        bottom: 70px !important;
        right: 16px !important;
        /* top: 6px; */
    }
}

/*invite referrals */
#tnp_js-modal #tnp_js-modal-content #tnp_js-modal-close-button {
    top: -25px !important;
    right: -2px !important;
    background: url("./assets/icons/rest/close-icon-white.svg") !important;
    background-repeat: no-repeat !important;
}

#invtrfl_btn_widget {
    display: none !important
}
